
















































import { debounce } from 'lodash-es';
import Vue from 'vue';
import AlarmsList from '../../components/alarms/AlarmsList.vue';
import PageBreadcrumbs from '../../components/app/PageBreadcrumbs.vue';
import SiteLastUpdate from '../../components/sites/SiteLastUpdate.vue';
import UnitInfo from '../../components/units/UnitInfo.vue';
import UnitParameters from '../../components/units/UnitParameters.vue';
import { Thing } from '../../models/Thing';
import { Unit } from '../../models/Unit';
import { LoadingService } from '../../services/loading/LoadingService';
import { ThingStateFull } from '../../services/things/models/ThingStateFull';
import { ThingsMQTTService } from '../../services/things/ThingsMQTTService';
import { ThingsService } from '../../services/things/ThingsService';
import { pickDefined } from '../../utils/Utils';

const REFRESH_INTERVAL_IN_MS = 1000 * 10;
const TEN_MINUTES_IN_MS = 1000 * 60 * 10;

export default Vue.extend({
  name: 'UnitPage',

  components: {
    AlarmsList,
    PageBreadcrumbs,
    SiteLastUpdate,
    UnitInfo,
    UnitParameters,
  },

  props: {
    thingId: {
      type: String,
      required: true,
    },
    unitId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      thing: Thing.empty(),
      unit: Unit.empty(),

      refreshInterval: -1,
    };
  },

  async created(): Promise<void> {
    LoadingService.setIsLoading(true);

    this.debouncedForceThingUpdate();

    await this.loadThing(true);
    this.setupListeners();

    LoadingService.setIsLoading(false);

    this.refreshInterval = window.setInterval(() => {
      this.loadThing(true);
    }, REFRESH_INTERVAL_IN_MS);
  },

  beforeDestroy(): void {
    this.destroyListeners();
    window.clearInterval(this.refreshInterval);
  },

  computed: {
    debouncedLoadThing(): () => void {
      return debounce(() => this.loadThing(true), REFRESH_INTERVAL_IN_MS);
    },
    debouncedForceThingUpdate(): () => void {
      return debounce(this.forceThingUpdate, TEN_MINUTES_IN_MS, {
        leading: true,
      });
    },
  },

  methods: {
    async loadThing(forceRefresh = false): Promise<void> {
      const thing = await ThingsService.getThing(this.thingId, forceRefresh);
      this.thing = thing;

      const unit = thing.units?.find(unit => unit.id === this.unitId);
      if (unit) {
        const definedUnit = pickDefined(unit);
        this.unit = new Unit({ ...this.unit, ...definedUnit });
      }
    },
    forceThingUpdate(): void {
      ThingsMQTTService.forceThingUpdate(
        this.thing.domain.topic,
        this.thing.thingName
      );
    },
    setupListeners(): void {
      ThingsMQTTService.subscribeToUpdate(
        this.thing.domain.topic,
        this.thing.thingName,
        true
      );
      ThingsMQTTService.subscribeToNetworkedThingUpdate(
        this.thing.domain.topic,
        this.thing.thingName,
        this.unit.id
      );

      ThingsMQTTService.onMessage(async (topic, _, json) => {
        const reportedState = json?.state?.reported;
        if (!reportedState) return;

        if (topic.includes(this.unit.id)) {
          const updatedUnit = pickDefined(
            Unit.fromThingUnit(reportedState, this.unit.id)
          );
          this.unit = new Unit({ ...this.unit, ...updatedUnit });
        } else {
          const updatedThing = pickDefined(
            Thing.fromThingState(this.thing, reportedState as ThingStateFull)
          );
          this.thing = new Thing({ ...this.thing, ...updatedThing });
        }

        this.debouncedLoadThing();
      });
      ThingsMQTTService.onError(() => {
        // ? Catch errors caused by removed units being disconnected.
      });
    },
    destroyListeners(): void {
      ThingsMQTTService.disconnect();
    },
  },
});
