






















import Vue from 'vue';
import { Thing } from '../../../models/Thing';
import { Unit } from '../../../models/Unit';
import RemoveUnitDialog from './RemoveUnitDialog.vue';

export default Vue.extend({
  name: 'RemoveUnitDialogAction',

  components: {
    RemoveUnitDialog,
  },

  props: {
    thing: {
      type: Thing,
      required: true,
    },
    unit: {
      type: Unit,
      required: true,
    },
  },
});
