

























































































































































































import { isEqual } from 'lodash-es';
import Vue from 'vue';
import { Thing } from '../../../models/Thing';
import { Unit } from '../../../models/Unit';
import { VForm } from '../../../plugins/vuetifyTypes';
import { AlarmsService } from '../../../services/alarms/AlarmsService';
import { AlarmPrio } from '../../../services/alarms/models/AlarmPrio';
import { AlarmSetting } from '../../../services/alarms/models/AlarmSetting';
import { AuthService } from '../../../services/auth/AuthService';
import { SnackbarType } from '../../../services/snackbar/models/SnackbarType';
import { SnackbarService } from '../../../services/snackbar/SnackbarService';

function createEmptyAlarmSetting(alarmPrio: AlarmPrio): AlarmSetting {
  return {
    email: false,
    priority: alarmPrio,
    sms: false,
    unit: '',
  };
}

export default Vue.extend({
  name: 'EditUnitAlarmSettingsDialog',

  props: {
    thing: {
      type: Thing,
      required: true,
    },
    unit: {
      type: Unit,
      required: true,
    },
  },

  watch: {
    async isDialogOpen(): Promise<void> {
      this.isLoading = true;

      await this.loadAlarmSettings();

      this.isLoading = false;
    },
  },

  async created(): Promise<void> {
    this.isLoading = true;

    await this.loadAlarmSettings();

    this.isLoading = false;
  },

  data() {
    return {
      AlarmPrio,
      isLoading: false,
      isDialogOpen: false,

      oldAlarmSettingA: createEmptyAlarmSetting(AlarmPrio.A),
      oldAlarmSettingB: createEmptyAlarmSetting(AlarmPrio.B),
      oldAlarmSettingInfo: createEmptyAlarmSetting(AlarmPrio.Info),

      alarmSettingA: createEmptyAlarmSetting(AlarmPrio.A),
      alarmSettingB: createEmptyAlarmSetting(AlarmPrio.B),
      alarmSettingInfo: createEmptyAlarmSetting(AlarmPrio.Info),
    };
  },

  computed: {
    isAnyAlarmSettingsChanged(): boolean {
      return (
        !isEqual(this.alarmSettingA, this.oldAlarmSettingA) ||
        !isEqual(this.alarmSettingB, this.oldAlarmSettingB) ||
        !isEqual(this.alarmSettingInfo, this.oldAlarmSettingInfo)
      );
    },
    currentUser(): unknown {
      return AuthService.getCurrentUser();
    },
  },

  methods: {
    async onSubmit(): Promise<void> {
      this.isLoading = true;
      try {
        const validation = (this.$refs.form as VForm).validate();

        if (validation) {
          const alarms: AlarmSetting[] = [];

          if (this.alarmSettingA.sms || this.alarmSettingA.email) {
            alarms.push({ ...this.alarmSettingA, unit: this.unit.id });
          }

          if (this.alarmSettingB.sms || this.alarmSettingB.email) {
            alarms.push({ ...this.alarmSettingB, unit: this.unit.id });
          }

          if (this.alarmSettingInfo.sms || this.alarmSettingInfo.email) {
            alarms.push({ ...this.alarmSettingInfo, unit: this.unit.id });
          }

          await AlarmsService.updateAlarmSettings(
            this.thing.thingName,
            this.unit.id,
            alarms
          );
          SnackbarService.open(
            this.$t('snackbar.updated', { name: this.unit.name }),
            SnackbarType.Success
          );
          this.isDialogOpen = false;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        SnackbarService.open(
          this.$t('snackbar.somethingWentWrong'),
          SnackbarType.Error
        );
        this.isLoading = false;
      }
    },
    onClickOpen(): void {
      this.isDialogOpen = true;
    },
    onClickClose(): void {
      this.isDialogOpen = false;
    },
    resetAlarmSettings(): void {
      this.oldAlarmSettingA = createEmptyAlarmSetting(AlarmPrio.A);
      this.oldAlarmSettingB = createEmptyAlarmSetting(AlarmPrio.B);
      this.oldAlarmSettingInfo = createEmptyAlarmSetting(AlarmPrio.Info);

      this.alarmSettingA = createEmptyAlarmSetting(AlarmPrio.A);
      this.alarmSettingB = createEmptyAlarmSetting(AlarmPrio.B);
      this.alarmSettingInfo = createEmptyAlarmSetting(AlarmPrio.Info);
    },
    async loadAlarmSettings(): Promise<void> {
      if (this.thing.thingName && this.unit.id) {
        const alarmSettings = await AlarmsService.getAlarmSettings(
          this.thing.thingName,
          this.unit.id
        );

        if (alarmSettings.length === 0) {
          this.resetAlarmSettings();
        } else {
          alarmSettings.forEach(alarmSetting => {
            if (alarmSetting.priority === AlarmPrio.A) {
              this.alarmSettingA = { ...alarmSetting };
              this.oldAlarmSettingA = { ...this.alarmSettingA };
            }
            if (alarmSetting.priority === AlarmPrio.B) {
              this.alarmSettingB = { ...alarmSetting };
              this.oldAlarmSettingB = { ...this.alarmSettingB };
            }
            if (alarmSetting.priority === AlarmPrio.Info) {
              this.alarmSettingInfo = { ...alarmSetting };
              this.oldAlarmSettingInfo = { ...this.alarmSettingInfo };
            }
          });
        }
      }
    },
  },
});
