































































































import { Thing } from '../../models/Thing';
import { Unit } from '../../models/Unit';
import ConnectUnitDialogAction from './dialogs/ConnectUnitDialogAction.vue';
import EditUnitAlarmSettingsDialogAction from './dialogs/EditUnitAlarmSettingsDialogAction.vue';
import EditUnitDialogAction from './dialogs/EditUnitDialogAction.vue';
import RemoveUnitDialogAction from './dialogs/RemoveUnitDialogAction.vue';
import UnitAlerts from './UnitAlerts.vue';
import Vue from 'vue';

type ListItem = {
  label: unknown;
  value: () => unknown;
};

export default Vue.extend({
  name: 'UnitInfo',

  components: {
    ConnectUnitDialogAction,
    EditUnitAlarmSettingsDialogAction,
    EditUnitDialogAction,
    RemoveUnitDialogAction,
    UnitAlerts,
  },

  props: {
    unit: {
      required: true,
      type: Unit,
    },
    thing: {
      required: true,
      type: Thing,
    },
  },

  data() {
    return {
      isUnitDetailsOpen: false,
    };
  },

  computed: {
    unitDetailsIcon(): string {
      return this.isUnitDetailsOpen ? 'mdi-chevron-up' : 'mdi-chevron-down';
    },
    items(): ListItem[] {
      return [
        {
          label: this.$t('component.unitInfo.name'),
          value: () => this.unit.name,
        },
        {
          label: this.$t('component.unitInfo.ipAddress'),
          value: () => this.unit.ipAddress,
        },
        {
          label: this.$t('component.unitInfo.extPort'),
          value: () => this.unit.extPort,
        },
        {
          label: this.$t('component.unitInfo.productType'),
          value: () => this.unit.productType,
        },
        {
          label: this.$t('component.unitInfo.type'),
          value: () => this.unit.type,
        },
        {
          label: this.$t('component.unitInfo.version'),
          value: () => this.unit.version,
        },
      ];
    },
  },

  methods: {
    onClickToggleIsUnitDetailsOpen(): void {
      this.isUnitDetailsOpen = !this.isUnitDetailsOpen;
    },
  },
});
