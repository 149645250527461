

























import { Thing } from '../../../models/Thing';
import { Unit } from '../../../models/Unit';
import EditUnitAlarmSettingsDialog from './EditUnitAlarmSettingsDialog.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'EditUnitAlarmSettingsDialogAction',

  components: { EditUnitAlarmSettingsDialog },

  props: {
    unit: {
      required: true,
      type: Unit,
    },
    thing: {
      type: Thing,
      required: true,
    },
  },
});
