





































































import Vue from 'vue';
import { Thing } from '../../../models/Thing';
import { Unit } from '../../../models/Unit';
import { VForm } from '../../../plugins/vuetifyTypes';
import { SnackbarType } from '../../../services/snackbar/models/SnackbarType';
import { SnackbarService } from '../../../services/snackbar/SnackbarService';
import { ThingStateFull } from '../../../services/things/models/ThingStateFull';
import { ThingsMQTTService } from '../../../services/things/ThingsMQTTService';
import { ThingsService } from '../../../services/things/ThingsService';
import { UnitsService } from '../../../services/units/UnitsService';

const FOUR_MINUTES_IN_MS = 1000 * 60 * 4;

export default Vue.extend({
  name: 'RemoveUnitDialog',

  props: {
    thing: {
      type: Thing,
      required: true,
    },
    unit: {
      type: Unit,
      required: true,
    },
  },

  data() {
    return {
      isDialogOpen: false,
      isLoading: false,
      isLoadingTimeout: -1,
    };
  },

  methods: {
    onClickOpen(): void {
      this.isDialogOpen = true;
    },
    onClickClose(): void {
      this.isDialogOpen = false;
    },
    async onSubmit(): Promise<void> {
      this.isLoading = true;
      try {
        const validation = (this.$refs.form as VForm).validate();

        if (validation) {
          await ThingsMQTTService.subscribeToUpdate(
            this.thing.domain.topic,
            this.thing.thingName
          );
          ThingsMQTTService.onMessage(async (topic, _, json) => {
            if (!this.isDialogOpen) return;

            const reportedState = json?.state?.reported as ThingStateFull;
            if (reportedState) {
              const unit = reportedState[this.unit.id];

              if (!unit?.installed) {
                if (this.thing.isNetworkedThingsSupported) {
                  const unitThingName = `${this.thing.thingName}__${this.unit.id}`;
                  await ThingsService.removeThing(unitThingName).catch(
                    () => undefined
                  );
                }
                SnackbarService.open(
                  this.$t('snackbar.removed', { name: this.unit.name }),
                  SnackbarType.Success
                );
                this.isDialogOpen = false;
                this.isLoading = false;
                clearTimeout(this.isLoadingTimeout);

                await this.$router.push({
                  name: 'Site',
                  params: { thingId: this.thing.thingName },
                  query: this.$route.query,
                });
              }
            }
          });

          await UnitsService.removeUnit({
            unit: this.unit,
            thing: this.thing,
          });

          // ? If the isLoading for some reason isn't toggled back to false use a timeout to ensure it is set to false.
          this.isLoadingTimeout = window.setTimeout(() => {
            this.isLoading = false;
            clearTimeout(this.isLoadingTimeout);
          }, FOUR_MINUTES_IN_MS);
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        SnackbarService.open(
          this.$t('snackbar.somethingWentWrong'),
          SnackbarType.Error
        );
        this.isLoading = false;
      }
    },
  },
});
