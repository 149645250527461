
























































































































import { cloneDeep, isEqual } from 'lodash-es';
import Vue from 'vue';
import { Thing } from '../../../models/Thing';
import { Unit } from '../../../models/Unit';
import { VForm } from '../../../plugins/vuetifyTypes';
import { SnackbarType } from '../../../services/snackbar/models/SnackbarType';
import { SnackbarService } from '../../../services/snackbar/SnackbarService';
import { UnitsService } from '../../../services/units/UnitsService';
import { formValidator } from '../../../utils/FormValidator';

export default Vue.extend({
  name: 'EditUnitDialog',

  props: {
    thing: {
      type: Thing,
      required: true,
    },
    unit: {
      type: Unit,
      required: true,
    },
  },

  computed: {
    isNewUnitChanged(): boolean {
      return !isEqual(this.unit, this.newUnit);
    },
    unitLabel(): unknown {
      return this.$t('component.editUnitDialog.unitLabel', {
        name: this.unit.name || 'Unknown',
        id: this.unit.id,
      });
    },
  },

  watch: {
    isDialogOpen(): void {
      // ? Use timeout to avoid the user seeing the "loading" of values.
      setTimeout(() => this.loadUnit(), 100);
    },
  },

  created(): void {
    this.loadUnit();
  },

  data() {
    return {
      formValidator,
      formData: {
        confirmPassword: '',
      },
      newUnit: Unit.empty(),
      isDialogOpen: false,
      isLoading: false,
    };
  },

  methods: {
    loadUnit(): void {
      const unitClone = cloneDeep(this.unit);
      this.newUnit = new Unit(unitClone);
    },
    onClickOpen(): void {
      this.isDialogOpen = true;
    },
    onClickClose(): void {
      this.isDialogOpen = false;
    },
    async onSubmit(): Promise<void> {
      this.isLoading = true;
      try {
        const validation = (this.$refs.form as VForm).validate();

        if (validation) {
          await UnitsService.updateUnit({
            thing: this.thing,
            newUnit: this.newUnit,
            oldUnit: this.unit,
          });
          SnackbarService.open(
            this.$t('snackbar.updated', { name: this.unit.name }),
            SnackbarType.Success
          );
          this.isDialogOpen = false;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        SnackbarService.open(
          this.$t('snackbar.somethingWentWrong'),
          SnackbarType.Error
        );
        this.isLoading = false;
      }
    },
  },
});
