var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('edit-unit-alarm-settings-dialog',{attrs:{"unit":_vm.unit,"thing":_vm.thing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var onClickOpen = ref.onClickOpen;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"accent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"accent","disabled":!_vm.unit.isOnline && !_vm.unit.isAlarmsSupported},on:{"click":function($event){$event.preventDefault();return onClickOpen.apply(null, arguments)}}},'v-btn',Object.assign({}, attrs, tooltipAttrs),false),Object.assign({}, on, tooltipOn)),[_c('v-icon',[_vm._v("mdi-alarm")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('component.editUnitAlarmSettingsDialogAction.alarmSettings')))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }